import React from 'react'
import Layout from '../../components/Layout'

export default () => (
  <Layout>
    <section className="section">
      <div
        className="full-width-image margin-top-0"
        style={{
          backgroundImage: `url(/img/conifer-green-landscape-1036660.jpg)`,
          backgroundPosition: `top left`,
          backgroundAttachment: `fixed`,
        }}
      >
        <div
          style={{
            display: 'flex',
            height: '150px',
            lineHeight: '1',
            justifyContent: 'space-around',
            alignItems: 'left',
            flexDirection: 'column',
          }}
        >
          <h1
            className="has-text-weight-bold is-size-3-mobile is-size-2-tablet is-size-1-widescreen"
            style={{
              boxShadow:
                '#002244 0.5rem 0px 0px, #002244 -0.5rem 0px 0px',
              backgroundColor: '#002244',
              color: 'white',
              lineHeight: '1',
              padding: '0.25em',
            }}
          >
            Thank You!
          </h1>
          <h3
            className="has-text-weight-bold is-size-5-mobile is-size-5-tablet is-size-4-widescreen"
            style={{
              boxShadow:
                '#002244 0.5rem 0px 0px, #000 -0.5rem 0px 0px',
              backgroundColor: '#002244',
              color: 'white',
              lineHeight: '1',
              padding: '0.25em',
            }}
          >
            <h3>I will get back to you as soon as possible.</h3>
          </h3>
        </div>
      </div>
    </section>
  </Layout>
);
